import Hero  from "../components/Hero";


export function Home(){
    return (
    <>
    <Hero/>
    
    </>
)
}