import React, { Component } from 'react';
import { Link, Route, Routes } from "react-router-dom";

class Navbar extends Component {
    state = {  } 
    render() { 
        return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">LOGO</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                  <Link to="/" className="nav-link">Home</Link>
                  <Link to="/ueber_mich" className="nav-link">Über mich</Link>
                  <Link to="/links" className="nav-link">Links</Link>
                  <Link to="/loops" className="nav-link">Loops</Link>
                  <Link to="/mysql" className="nav-link">Mysql</Link>
                  
                </div>
              </div>
            </div>
          </nav>
        );
    }
}
 
export default Navbar;